import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  InputGroup,
  Button,
  Alert,
  FormControl,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import authHeader from "../../auth-header";

import DeleteConfirmation from "../../components/delete";

let ordered = [];

const EditOrder = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  const [order, setOrder] = useState([]);
  const [parts, setParts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [notFound, setNotFound] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [searchType, setSearchType] = useState(0);
  const searchRef = useRef();

  const [searchItems, setSearchItems] = useState([
    { title: "All", text: "All Fields", field: "" },
    { title: "Part Number", text: "Part Number", field: "PartNum" },
    { title: "ALU PO Numberrr", text: "ALU PO Number", field: "ALUPONum" },
    { title: "Customer PO Number", text: "Customer PO Number", field: "PONum" },
    { title: "SO Number", text: "SO Number", field: "SONum" },
  ]);

  useEffect(() => {
    if (props.customer === "34") {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "ALU PO Numberrr", text: "ALU PO Number", field: "ALUPONum" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
      ]);
    } else {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "Vendor", text: "Vendor", field: "Vendor" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
      ]);
    }
  }, [props.customer]);

  useEffect(() => {
    //setParts([]);
    getOrder(1);
  }, [params.orderId]);

  const searchParts = () => {
    axios
      .get("/api/orders/createSearch", {
        params: {
          owner: order.Owner,
          value: searchRef.current.value,
          field: searchItems[searchType].field,
        },
        headers: authHeader(),
      })
      .then(function (response) {
        // console.log(response.data);
        setSearchResults(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    // console.log("searchHandler");
    ordered = [];

    searchParts();

    // axios
    //   .get("/api/orders/createSearch", {
    //     params: {
    //       owner: order.Owner,
    //       value: searchRef.current.value,
    //       field: searchItems[searchType].field,
    //     },
    //     headers: authHeader(),
    //   })
    //   .then(function (response) {
    //     // console.log(response.data);
    //     setSearchResults(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const getOrder = (orderType) => {
    axios
      .get(`/api/orders/lookup?type=${orderType}&orderNum=${params.orderId}`, {
        headers: authHeader(),
      })
      .then(function (response) {
        setNotFound(false);
        setOrder(response.data.info);
        setParts(response.data.parts);
        // console.log(response.data.parts);
        setShipping(response.data.shipping);
      })
      .catch(function (error) {
        //  error, assume not found
        setNotFound(true);
        console.log(error);
      });
  };

  const deletePartHandler = () => {
    //console.log("DeletePartHandler");
    setParts((prevParts) => {
      const updatedParts = prevParts.filter((item) => item.id !== selectedItem);
      return updatedParts;
    });
    setDeleteModalShow(false);

    axios
      .post(
        "/api/orders/deleteBOM",
        {
          id: selectedItem,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response.data);
        if (response.data.Count === 0) navigate("/");
        // returns number of records in order
        // update search part on screen?
        else searchParts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deletePart = (id) => {
    // console.log("deletePart " + id);
    setSelectedItem(id);
    setDeleteModalShow(true);
  };

  const addParts = (partstoAdd) => {
    console.log('addParts',props.customer)
    axios
      .post(`/api/orders/insertBOM?customer=${props.customer}`, partstoAdd, { headers: authHeader() })
      .then(function (response) {
        // console.log("addParts");
        // console.log(response.data);
        setParts((prevParts) => [...prevParts, ...response.data]);
        searchParts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addPartsHandler = () => {
    // console.log("addPartsHandler");
    // make a list of the ordered parts
    const partsToAdd = searchResults
      .map((item, index) =>
        ordered[index] > "0" && ordered[index] <= item.Quantity // only add if quantity entered is not more that what is available
          ? // ? { ...item, Quantity: item["Quantity"] - ordered[index] }
            {
              ...item,
              OrderNum: order.OrderNum,
              Ordered: parseInt(ordered[index]),
              SiteName: order.SiteName,
              OrderedFor: order.OrderedFor,
            }
          : item
      )
      .filter((item) => item.Ordered > 0);
    // console.log(partsToAdd);

    if (partsToAdd.length > 0) addParts(partsToAdd);
    ordered = [];

    // update the Quantity from the ordered array only if not an error
    // setSearchResults(
    //   searchResults.map((item, index) => {
    //     if (ordered[index] > "0") {
    //       if (ordered[index] <= item.Quantity) {
    //         // console.log("set ordered %d to 0", index);
    //         const quantity = ordered[index];
    //         ordered[index] = 0;
    //         return {
    //           ...item,
    //           Quantity: item.Quantity - quantity,
    //           valid: true,
    //         };
    //       } else {
    //         return { ...item, valid: false };
    //       }
    //     } else return { ...item, valid: true };
    //   })
    // );
  };

  const Summary = () => (
    <Table responsive>
      <thead>
        <tr>
          <th>Site</th>
          <th>Date Ordered</th>
          <th>Pickup Date</th>
          <th>Requested By</th>
          <th>Requested For</th>
          {typeof order.PickedBy !== "undefined" && <th>Picked By</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{order.SiteName} </td>
          <td>{order.DateOrdered} </td>
          <td>{order.DatePickUp} </td>
          <td>{order.OrderedBy} </td>
          <td>{order.OrderedFor} </td>
          {typeof order.PickedBy !== "undefined" && <td>{order.PickedBy} </td>}
        </tr>
      </tbody>
    </Table>
  );

  const Notes = () => {
    return (
      <>
        {order.notes?.length > 0 && (
          <Card border="primary" className="mb-3">
            <Card.Body>
              {order.notes.map((item, index) => (
                <Card.Text key={index}>{item.Notes}</Card.Text>
              ))}
              {/* <Card.Text>{order.notes[0].Notes}</Card.Text> */}
            </Card.Body>
          </Card>
        )}
      </>
    );
  };

  const Detail = () => (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Owner</th>
          <th>Part Number</th>
          <th>{props.customer === "34" ? "ALU PO #" : "Vendor"}</th>
          <th>Customer PO#</th>
          <th>SO #</th>
          <th>Warehouse</th>
          <th>Description</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        {parts.map((part, index) => (
          <tr key={part.id}>
            <td>{part.Owner} </td>
            <td>{part.PartNum} </td>
            <td>{part.ALUPONum} </td>
            <td>{part.PONum} </td>
            <td>{part.SONum} </td>
            <td>{part.Warehouse} </td>
            <td>{part.Description} </td>
            <td>{part.Quantity} </td>
            <td>
              <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                <i
                  className="bi-trash"
                  style={{
                    cursor: "pointer",
                    color: "red",
                  }}
                  onClick={() => deletePart(part.id)}
                />
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const SearchInput = ({ items, onSearch }) => (
    <Form onSubmit={(e) => onSearch(e)}>
      <Form.Group as={Col} lg={4} sm={7}>
        <Form.Label>Part Search</Form.Label>
        <InputGroup>
          <DropdownButton
            onSelect={(e) => setSearchType(e)}
            variant="outline-secondary"
            title={items[searchType].title}
            id="input-group-dropdown-1"
          >
            {items.map((item, i) => (
              <Dropdown.Item key={i} eventKey={i}>
                {item.text}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Form.Control type="text" ref={searchRef} />
          <Button type="submit">Search</Button>
        </InputGroup>
      </Form.Group>
    </Form>
  );

  const SearchResults = ({ parts }) => {
    return (
      <>
        <Alert variant="info">
          Search Results:
          <Button
            variant="outline-secondary"
            className="float-end"
            onClick={addPartsHandler}
          >
            Add to Order
          </Button>
        </Alert>

        <Table responsive>
          <thead className="table-light">
            <tr>
              <th>Owner</th>
              <th>Part #</th>
              <th>{props.customer === "34" ? "ALU #":"Vendor"}</th>
              <th>PO #</th>
              <th>SO #</th>
              <th>Description</th>
              <th>Serial #</th>
              <th>SKU #</th>
              <th>Stock</th>
              <th>Order</th>
            </tr>
          </thead>
          <tbody>
            {parts.map((item, index) => {
              return (
                <tr key={index + 1}>
                  <td>{item.Owner} </td>
                  <td>{item.PartNum} </td>
                  <td>{item.ALUPONum} </td>
                  <td>{item.PONum} </td>
                  <td>{item.SONum} </td>
                  <td>{item.Description} </td>
                  <td>{item.SerialNum} </td>
                  <td>{item.SKUNum} </td>
                  <td>{item.Quantity} </td>
                  <td style={{ width: "7rem" }}>
                    {typeof item.valud !== "undefined" ||
                    item.valid === false ? (
                      <FormControl
                        type="number"
                        style={{ backgroundColor: "red" }}
                        defaultValue={ordered[index]}
                        onChange={(e) => {
                          ordered[index] = e.target.value;
                        }}
                      />
                    ) : (
                      <FormControl
                        type="number"
                        onChange={(e) => {
                          ordered[index] = e.target.value;
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const ShowOrder = () => {
    return (
      parts?.length > 0 && (
        <>
          <DeleteConfirmation
            showModal={deleteModalShow}
            onDelete={deletePartHandler}
            msg={
              parts.length === 1 &&
              "If you delete this part, the order will be deleted including notes and address!"
            }
            onHide={() => setDeleteModalShow(false)}
          />
          <h4 style={{ backgroundColor: "#F0F0F0" }}>
            Edit Order #{params.orderId}
          </h4>
          <Summary />
          <Notes />
          <Detail />
        </>
      )
    );
  };

  return (
    <Container fluid>
      <ShowOrder />
      <Row className="mb-3">
        <Form onSubmit={searchHandler}>
          <Form.Group as={Col} lg={4} sm={7}>
            <Form.Label>Part Search</Form.Label>
            <InputGroup>
              <DropdownButton
                onSelect={(e) => setSearchType(e)}
                variant="outline-secondary"
                title={searchItems[searchType].title}
                id="input-group-dropdown-1"
              >
                {searchItems.map((item, i) => (
                  <Dropdown.Item key={i} eventKey={i}>
                    {item.text}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Control type="text" ref={searchRef} />
              <Button type="submit">Search</Button>
            </InputGroup>
          </Form.Group>
        </Form>

        {/* <SearchInput items={searchItems} onSearch={searchHandler} /> */}
      </Row>
      {searchResults?.length > 0 && <SearchResults parts={searchResults} />}
    </Container>
  );
};

export default EditOrder;
