import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import authHeader from "../../auth-header";
import useSWR from 'swr';

const fetcher = (url) => axios.get(url).then((res) => res.data);


const OrdersPending = (props) => {

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: "OrderNum",
      },
      {
        Header: "Owner",
        accessor: "Owner",
      },
      {
        Header: "Site",
        accessor: "SiteName",
      },
      {
        Header: "Date Ordered",
        accessor: "DateOrdered",
      },
      {
        Header: "Date Available",
        accessor: "DateAvailable",
      },
      {
        Header: "Ordered By",
        accessor: "OrderedBy",
      },
    ],
    []
  );


  function Orders({ columns, data }) {
    // Use the useTable Hook to send the columns and data to build the table
    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      rows, // rows for the table based on the data passed
      prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

    /* 
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    return (
      <>
        {/* <h3 style={{ textAlign: "center" }}>
          Pending Orders
        </h3> */}

        {/* <h4 style={{ backgroundColor: "#F0F0F0" }}>Pending Orders</h4> */}
        <h4>Pending Orders</h4>

        <Table hover responsive size="sm" {...getTableProps()}>
          {/* <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "bi-sort-down"
                          : "bi-sort-up-alt"
                        : ""
                    }
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}
          <thead className="table-light">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              // console.log(row.original.OrderNum)
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return j === 0 ? (
                      <td key={row.original.OrderNum}>
                        <Link to={`/order/${cell.value}?type=1`}>
                          {cell.render("Cell")}{" "}
                        </Link>
                      </td>
                    ) : (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }

  const { data, error } = useSWR(`/api/orders/pending?customer=${props.customer}`, fetcher);
  if (error) return "An error has occurred.";
  // if (!data) return "Loading...";
  // return <>{orders.length > 0 && <Orders columns={columns} data={data} />}</>;
  return <>{data && data.length > 0 && <Orders columns={columns} data={data} />}</>;
};

export default OrdersPending;
