import React, { useState, useEffect, Fragment } from 'react';
import { Table, Container, Button, Col, Card, FormControl, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import authHeader from '../../auth-header';

// const useFocus = () => {
//     console.log('useFocus')
//     const htmlElRef = useRef(null);
//     const setFocus = () => {
//         console.log('setFocus')
//       htmlElRef.current && htmlElRef.current.setFocus();
//     };
//     return [htmlElRef, setFocus];
//   };

const PickOrder = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  let arr = [];

  const [order, setOrder] = useState([]);
  const [parts, setParts] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [validSubmit, setValidSubmit] = useState(false);
  const [showError, setShowError] = useState(false);
  //   const [pickParts, setPickParts] = useState();

  useEffect(() => {
    axios
      .get(`/api/orders/getPickParts?orderNum=${params.orderId}`, { headers: authHeader() })
      .then(function (response) {
        setNotFound(false);
        //console.log(response.data);
        setOrder(response.data.info);
        setParts(response.data.parts);
        for (let i = 0; i < response.data.parts.length; i++) arr[i] = new Array(response.data.parts[i].details.length);
        // setPickParts(arr);
        // console.log(arr);
        // setShipping(response.data.shipping);
      })
      .catch(function (error) {
        //  error, assume not found
        setNotFound(true);
        console.log(error);
      });
  }, []);

  const submitPickHandler = () => {
    // console.log('submitPickHandler')
    axios
      .post(
        '/api/orders/submitPick',
        {
          orderNum: params.orderId,
          // employeeId: props.employeeId,
          parts: parts,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
        navigate('/');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const confirmPickHandler = () => {
    // console.log('confirmPickHandler');
    // console.log(parts);

    const newParts = parts.map((item) =>
      item.Quantity === item.Picked ? { ...item, valid: true } : { ...item, valid: false }
    );
    setValidSubmit(newParts.every((elem) => elem.valid === true));
    setParts(newParts);
  };

  const editPick = (value, i, j, quantity) => {
    console.log('editPick value=%s,index=%d,index2=%d,quantity=%d', value, i, j, quantity);
    if (value > quantity) {
      setShowError(true);
      return;
    }
    parts[i].details[j].Picked = parseInt(value);
    parts[i].Picked = parts[i].details.reduce((sum, cur) => sum + parseInt(cur.Picked), 0);
  };

  const Summary = () => (
    <Table responsive>
      <thead>
        <tr>
          <th>Site</th>
          <th>Date Ordered</th>
          <th>Pickup Date</th>
          <th>Requested By</th>
          <th>Requested For</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{order.SiteName} </td>
          <td>{order.DateOrdered} </td>
          <td>{order.DatePickUp} </td>
          <td>{order.OrderedBy} </td>
          <td>{order.OrderedFor} </td>
        </tr>
      </tbody>
    </Table>
  );

  const Notes = () => (
    // <Card border="primary" style={{marginBottom:'15px'}}>
    <Card border="primary" className="mb-3">
      {/* <Card style={{ marginLeft: '10px', marginRight: '10px'}}> */}
      <Card.Body>
        <Card.Text>{order.notes}</Card.Text>
      </Card.Body>
    </Card>
  );

  const Shipping = () => (
    // <Container fluid>
    <Table responsive>
      <thead>
        <tr>
          <th>Contact Name</th>
          <th>Contact Number</th>
          <th>Address</th>
          <th>Address 2</th>
          <th>City</th>
          <th>Province</th>
          <th>Postal Code</th>
        </tr>
      </thead>
      <tbody>
        <tr className="table-light">
          <td>{shipping.CompanyName} </td>
          <td>{shipping.ContactName} </td>
          <td>{shipping.ContactNumber} </td>
          <td>{shipping.Street} </td>
          <td>{shipping.Street2} </td>
          <td>{shipping.City} </td>
          <td>{shipping.Province} </td>
          <td>{shipping.PostalCode} </td>
        </tr>
      </tbody>
    </Table>
    // </Container>
  );

  const Detail = () => (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Owner</th>
          <th>Part Number</th>
          <th>{props.customer === '34' ? 'ALU PO #' : 'Vendor'}</th>
          <th>Customer PO#</th>
          <th>SO #</th>
          <th>Warehouse</th>
          <th>Row</th>
          <th>Rack</th>
          <th>Shelf</th>
          <th>SKU</th>
          <th>Description</th>
          <th>Ordered</th>
          <th>Stock</th>
          <th>Pick</th>
        </tr>
      </thead>
      <tbody>
        {parts.map((part, index, array) => {
          return [
            <tr
              key={'p' + index}
              className={part.valid ? 'table-success' : part.valid === false ? 'table-danger' : 'table-light'}
            >
              {/* style={item.Edited ? { background: "lightyellow" } : {}} */}

              <td>{part.Owner} </td>
              <td>{part.PartNum} </td>
              <td>{part.ALUPONum} </td>
              <td>{part.PONum} </td>
              <td>{part.SONum} </td>
              <td>{part.Warehouse} </td>
              <td colSpan={3} />
              <td>{part.SKUNum} </td>
              <td>{part.Description} </td>
              <td>{part.Quantity} </td>
              <td colSpan={2} />
            </tr>,
            [
              part.details.map((detail, index2) => (
                <tr className="align-middle" key={'p' + index + 'd' + index2}>
                  <td>{detail.Owner}</td>
                  <td>{detail.PartNum}</td>
                  <td>{detail.ALUPONum} </td>
                  <td>{detail.PONum} </td>
                  <td>{detail.SONum} </td>
                  <td>{detail.Warehouse} </td>
                  <td>{detail.Row} </td>
                  <td>{detail.Rack} </td>
                  <td>{detail.Shelf} </td>
                  <td>{detail.SKUNum} </td>
                  <td colSpan={2} />
                  <td>{detail.Quantity} </td>
                  {/* <PartPick/> */}
                  <td style={{ width: '5rem' }}>
                    <FormControl
                      key={'p' + index + 'in' + index2}
                      type="number"
                      //   defaultValue={pickParts[index][index2]}
                      defaultValue={detail.Picked}
                      //   ref={inputRef}
                      onBlur={(e) => editPick(e.target.value, index, index2, detail.Quantity)}
                      //   onBlur={(e) => console.log('left input'+e.target.value)}
                    />
                  </td>
                </tr>
              )),
            ],
            [
              <Fragment key={'d' + index}>
                {index + 1 < array.length && (
                  <tr className="table-dark">
                    <td colSpan={14} />
                  </tr>
                )}
              </Fragment>,
            ],
          ];
        })}
      </tbody>
      {/* <tfoot>
        <tr>
          <td colSpan={14} />
        </tr>
      </tfoot> */}
    </Table>
  );

  const ShowOrder = () => (
    <Container fluid>
      <h3 className="mt-3" style={{ textAlign: 'center' }}>
        Pick Order # {params.orderId}
      </h3>
      {typeof order.SiteName !== 'undefined' && <Summary />}
      {order.notes && <Notes />}
      <Detail />
      {/* {typeof shipping !== "undefined" && <Shipping />} */}
      <Col className="mb-3">
        <Button variant="outline-primary" className="me-5" onClick={() => confirmPickHandler()}>
          Confirm Pick
        </Button>
        <Button variant="primary" className="me-5" disabled={!validSubmit} onClick={() => submitPickHandler()}>
          Submit Pick
        </Button>
        {/* <Button
            variant="outline-secondary"
            // onClick={() => setEditModalShow(true)}
          >
            Reset
          </Button> */}
      </Col>
      <Error showModal={showError} onHide={() => setShowError(false)} />
    </Container>
  );

  const Error = (props) => (
    <Modal show={props.showModal} onHide={props.onHide} size="md" centered animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>Invalid Pick Amount</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onHide}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {notFound && (
        <h3 className="mt-3" style={{ textAlign: 'center' }}>
          Not found
        </h3>
      )}
      {parts.length > 0 && <ShowOrder />}
    </>
  );
};

export default PickOrder;
