import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

import authHeader from "../../auth-header";

const AddOwner = (props) => {
  const ownerRef = useRef();
  const customerRef = useRef();
  const [customers, setCustomers] = useState();

  useEffect(() => {
    // console.log("addUser useEffect");
    axios
      .get("/api/customers/list", {
        headers: authHeader(),
      })
      .then(function (response) {
        // console.log(response.data);
        setCustomers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const addHandler = (e) => {
    e.preventDefault();
    const data = {
      name: ownerRef.current.value,
      customer: customerRef.current.value,
    };
    props.onAdd(data);
    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      animation={false}
    >
      <Form onSubmit={addHandler}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Owner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label>Owner</Form.Label>
            <Form.Control
              style={{ resize: "none" }}
              placeholder="Enter Owner"
              ref={ownerRef}
              autoFocus
              required
            />
          </Form.Group>
          {customers && (
            <Form.Group className="mb-2">
              <Form.Label>Customer</Form.Label>
              <Form.Select ref={customerRef}>
                {customers.map((item) => (
                  <option key={item.id} s>
                    {item.customer}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={props.onHide}>
            Cancel
          </Button>
          <Button type="submit">Add</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddOwner;
