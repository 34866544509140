import { Button, Modal } from "react-bootstrap";

const ConfirmRequest = (props) => {
  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      size="md"
      // centered
      // animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {props.message}
        {/* <div className="alert alert-info text-center">{props.message}</div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={props.onConfirm}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmRequest;
