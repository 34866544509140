import React, { useRef, useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AddCustomer = (props) => {
  const customerRef = useRef();

  const addHandler = (e) => {
    e.preventDefault();
    const data = {
      name: customerRef.current.value,
    };
    props.onAdd(data);
    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      animation={false}
    >
      <Form onSubmit={addHandler}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Customer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label>Customer</Form.Label>
            <Form.Control
              style={{ resize: "none" }}
              placeholder="Enter Customer"
              ref={customerRef}
              autoFocus
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={props.onHide}>
            Cancel
          </Button>
          <Button type="submit">Add</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddCustomer;
