import React, { useRef, useState, useEffect, createContext } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'

import axios from 'axios'

import authHeader from '../../auth-header'

const EditUser = props => {
  const activeRef = useRef()
  const userRef = useRef()
  const passwordRef = useRef()
  const nameRef = useRef()
  const emailRef = useRef()
  const roleRef = useRef()
  const customerRef = useRef()
  const [customers, setCustomers] = useState([])
  const [role, setRole] = useState(0)

  const handleHide = () => {
    props.onHide()
    setCustomers([])
  }

  const handleShow = () => {
    // console.log("handleShow", props.item);
    axios
      .get(`/api/customers/list?id=${props.item.employeeId}`, {
        headers: authHeader()
      })
      .then(function (response) {
        // console.log('customers',response.data);
        setCustomers(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    setRole(props.item.role)

    //   // if editing yourself, don't allow changing of Role.  Make it read only?
  }

  const saveHandler = () => {
    let customerList = ''
    if (role === 0) {
      // customer.  convert customer name to id
      const found = customers.find(
        cust => cust.customer === customerRef.current.value
      )
      customerList = found.id
    } else if (role === 1) {
      // user.  make commalist of checked
      customers.forEach(element => {
        if (element.checked) {
          if (customerList != '') customerList += ','
          customerList += element.id
        }
      })
    }

    const data = {
      active: activeRef.current.checked,
      employeeId: props.item.employeeId,
      username: userRef.current.value,
      password: passwordRef.current.value,
      realname: nameRef.current.value,
      emailaddr: emailRef.current.value,
      role: roleRef.current.value,
      customers: customerList
    }
    props.onEdit(data)
    handleHide()
  }

  const handleRoleChange = value => {
    setRole(parseInt(value))
  }

  const handleCustomerClick = (checked, id) => {
    // console.log("handleCustomerClick", checked, id);
    setCustomers(current =>
      current.map(obj => {
        if (obj.id === id) {
          return { ...obj, checked: checked ? 1 : 0 }
        }
        return obj
      })
    )
  }

  return (
    <Modal
      show={props.showModal}
      onHide={handleHide}
      onShow={handleShow}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      //   centered
      backdrop='static'
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Edit {props.item.realname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className='mb-2'>
          <Form.Check
            type='checkbox'
            label='Active'
            ref={activeRef}
            defaultChecked={props.item.active}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            style={{ resize: 'none' }}
            defaultValue={props.item.username}
            ref={userRef}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            style={{ resize: 'none' }}
            // defaultValue={props.item.password}
            placeholder='Set a new password'
            ref={passwordRef}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label>Real Name</Form.Label>
          <Form.Control
            style={{ resize: 'none' }}
            defaultValue={props.item.realname}
            ref={nameRef}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            style={{ resize: 'none' }}
            defaultValue={props.item.emailaddr}
            ref={emailRef}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label>Role</Form.Label>
          <Form.Select
            ref={roleRef}
            defaultValue={props.item.role}
            onChange={e => handleRoleChange(e.target.value)}
          >
            <option value={0}>Customer</option>
            <option value={1}>User</option>
            <option value={2}>Admin</option>
          </Form.Select>
        </Form.Group>
        {role === 0 && (
          <Form.Group className='mb-2'>
            <Form.Label>Customer</Form.Label>
            <Form.Select ref={customerRef} defaultValue={props.item.customer}>
              {customers.map(item => (
                <option key={item.id}>{item.customer}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        {role === 1 && (
          <Form.Group>
            <Form.Label>Customers</Form.Label>
            <ListGroup.Item>
              {customers.map(item => (
                <Form.Check
                  key={item.id}
                  type='checkbox'
                  label={item.customer}
                  defaultChecked={item.checked}
                  onChange={e => handleCustomerClick(e.target.checked, item.id)}
                />
              ))}
            </ListGroup.Item>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='default' onClick={handleHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditUser
