import React, { useState, useRef, useMemo } from "react";

import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import FormControl from "react-bootstrap/FormControl";

import axios from "axios";
import * as XLSX from "xlsx";
import { useTable, useSortBy, usePagination } from "react-table";

import authHeader from "../../auth-header";
import OwnerSelect from "../../components/ownerSelect";

const OutboundSearch = (props) => {
  const [results, setResults] = useState([]);
  const [owner, setOwner] = useState("");
  const partNumRef = useRef();
  const [searchState, setSearchState] = useState(0);

  const startDateRef = useRef();
  const endDateRef = useRef();

  const columns = useMemo(
    () => [
      {
        Header: "Date Completed",
        accessor: "DatePickUp",
      },
      {
        Header: "Owner",
        accessor: "Owner",
      },
      {
        Header: "SKU",
        accessor: "SKUNum",
      },
      {
        Header: "Part #",
        accessor: "PartNum",
        disableSortBy: true,
      },
      {
        Header: "SO #",
        accessor: "SONum",
        disableSortBy: true,
      },
      {
        Header: "Vendor",
        accessor: "Vendor",
        disableSortBy: true,
      },
      {
        Header: "Order #",
        accessor: "OrderNum",
        disableSortBy: true,
      },
      {
        Header: "Site Name",
        accessor: "SiteName",
        disableSortBy: true,
      },
      {
        Header: "Description",
        accessor: "Description",
        disableSortBy: true,
      },
      {
        Header: "Quantity Shipped",
        accessor: "Quantity",
        disableSortBy: true,
      },
    ],
    []
  );

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchState(1);
    setResults([]);
    axios
      .get("/api/search/outbound3", {
        params: {
          owner: owner,
          startDate: startDateRef.current.value,
          endDate: endDateRef.current.value,
          partNum: partNumRef.current.value,
          customer: props.customer,
        },
        headers: authHeader(),
      })
      .then(function (response) {
        setResults(response.data);
        if (response.data.length > 0) setSearchState(0);
        else setSearchState(2);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const exportDataHandler = () => {
    // const table_elt = tableRef.current;

    // const workbook = XLSX.utils.table_to_book(table_elt, {
    //   dateNF: "yyyy-MM-DD hh:mm:ss",
    // });

    // XLSX.writeFile(workbook, "Report_Outbound.xlsx");
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(results);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Outbound");
    XLSX.writeFile(workbook, "Report_Outbound.xlsx");
  };

  const SearchResults = ({ columns, data }) => {
    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 100 },
      },
      useSortBy,
      usePagination
    );

    /* 
        Render the UI for your table
        - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
      */
    return (
      <>
        <Table hover responsive size="sm" {...getTableProps()}>
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.isSorted ? (column.isSortedDesc ? "bi-sort-down" : "bi-sort-up-alt") : ""}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              // console.log(row.original.OrderNum)
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <h4 style={{ backgroundColor: "#F0F0F0" }}>Outbound Search</h4>
        <Form onSubmit={searchHandler}>
          <Row className="mb-3">
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Owner</Form.Label>
              <OwnerSelect selectAll={true} setOwnerHandler={setOwner} customer={props.customer} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>From</Form.Label>
              <FormControl type="date" ref={startDateRef} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>To</Form.Label>
              <FormControl type="date" ref={endDateRef} />
            </Form.Group>
            <Form.Group as={Col} className="align-self-end" xl={4} lg={5} sm={8}>
              <Form.Label>Part Number</Form.Label>
              <InputGroup>
                <Form.Control type="text" ref={partNumRef} />
                {searchState !== 1 ? (
                  <Button type="submit">Search</Button>
                ) : (
                  <Button>
                    <Spinner animation="border" size="sm" />
                  </Button>
                )}
              </InputGroup>
            </Form.Group>
            <Col className="align-self-end">
              <Button variant="outline-primary" onClick={exportDataHandler}>
                Save Data (.xlsx)
              </Button>
            </Col>
          </Row>
        </Form>
        {searchState === 2 && <Alert variant="danger">No Results</Alert>}
        {results && results.length > 0 && <SearchResults columns={columns} data={results} />}
      </Container>
    </>
  );
};

export default OutboundSearch;
