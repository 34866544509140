import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import axios from 'axios'

import authHeader from '../../auth-header'

const MyNavBar = props => {
  const searchInputRef = useRef()
  let navigate = useNavigate()
  const [role, setRole] = useState(0)

  useEffect(() => {
    // console.log('navbar useEffect')
    axios
      .get('/api/users/role', {
        headers: authHeader()
      })
      .then(function (response) {
        // console.log(response.data.access);
        setRole(response.data.role)
        props.updateRole(response.data.role)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const searchHandler = e => {
    e.preventDefault()
    const enteredSerach = searchInputRef.current.value
    // console.log(enteredSerach);
    if (enteredSerach !== '') navigate(`/order/${enteredSerach}`)
    searchInputRef.current.value = ''
  }

  return (
    <Navbar
      collapseOnSelect
      expand='md'
      variant='dark'
      sticky='top'
      style={{ backgroundColor: '#496197' }}
      className='mb-2'
    >
      <Container fluid>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav activeKey={window.location.pathname}>
            <Nav.Link as={Link} href='/' to='/'>
              Home
            </Nav.Link>
            {role > 0 && (
              <>
                <Nav.Link
                  as={Link}
                  href='/inventory/input'
                  to='/inventory/input'
                >
                  Inventory Input
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  href='/inventory/batch'
                  to='/inventory/batch'
                >
                  Batch Upload
                </Nav.Link>
              </>
            )}
            <Nav.Link as={Link} href='/create' to='/create'>
              Create Order
            </Nav.Link>
            <NavDropdown title='Search'>
              <NavDropdown.Item
                as={Link}
                href='/search/inventory'
                to='/inventory/search'
              >
                Inventory
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                href='/search/inbound'
                to='/search/inbound'
              >
                Inbound
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                href='/search/outbound'
                to='/search/outbound'
              >
                Outbound
              </NavDropdown.Item>
            </NavDropdown>
            {role === 2 && (
              <>
                <NavDropdown title='Reports'>
                  <NavDropdown.Item
                    as={Link}
                    href='/reports/masterSearch'
                    to='/reports/masterSearch'
                  >
                    Master Search
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title='Admin'>
                  <NavDropdown.Item
                    as={Link}
                    href='/admin/users'
                    to='/admin/users'
                  >
                    Users
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    href='/admin/owners'
                    to='/admin/owners'
                  >
                    Owners
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    href='/admin/customers'
                    to='/admin/customers'
                  >
                    Customers
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        {/* <Form.Select className='form-control'>
          <option>Customer 1</option>
          <option>Customer 2</option>
          <option>Customer 3</option>
        </Form.Select> */}
        <Form onSubmit={searchHandler}>
          <Form.Control
            // className="form-control"
            // id="search"
            type='text'
            placeholder='Search Orders'
            ref={searchInputRef}
          ></Form.Control>
        </Form>
      </Container>
    </Navbar>
  )
}
export default MyNavBar
