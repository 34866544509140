import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const EditCustomer = (props) => {
  const customerRef = useRef();
  const [owners, setOwners] = useState();

  useEffect(() => {
    // console.log("editOwnwer useEffect");
    // axios
    //   .get("/api/customers/list", {
    //     headers: authHeader(),
    //   })
    //   .then(function (response) {
    //     setCustomers(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  const saveHandler = () => {
    const data = {
      name: customerRef.current.value,
      id: props.item.id,
    };
    props.onEdit(data);
    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Customer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label>Customer</Form.Label>
          <Form.Control
            style={{ resize: "none" }}
            defaultValue={props.item.customer}
            ref={customerRef}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCustomer;
