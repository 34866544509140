import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import OrdersCompleted from "./ordersCompleted";
import OrdersInProcess from "./ordersInProcess";
import OrdersPending from "./ordersPending";

const OrdersSummary = (props) => {
  console.log('OrdersSummary',props.customer)

  return (
    <Container fluid>
      <OrdersPending customer={props.customer} />
      <OrdersInProcess customer={props.customer} />
      <OrdersCompleted customer={props.customer}/>
    </Container>
  );
};

export default OrdersSummary;
