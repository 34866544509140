import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  Container,
  FormControl,
} from "react-bootstrap";
import axios from "axios";
import useSWR from "swr";
import * as XLSX from "xlsx";

import authHeader from "../../auth-header";

const fetcher = (url) =>
  axios.get(url, { headers: authHeader() }).then((res) => res.data);

let smallCount = 0;
let smallDays = 0;
let smallMinutes = 0;
let mediumCount = 0;
let mediumDays = 0;
let mediumMinutes = 0;
let largeCount = 0;
let largeDays = 0;
let largeMinutes = 0;

const OutboundReport = () => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const startDateRef = useRef();
  const endDateRef = useRef();
  const tableRef = useRef();
  const [shouldRender, setShouldRender] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setStartDate(searchParams.get("startDate") || "");
    setEndDate(searchParams.get("endDate") || "");
    if( searchParams.get("startDate") != null )
       setShouldRender(true);
  }, []);

  const searchHandler = (e) => {
    e.preventDefault();
    navigate({
      pathname: "/reports/outbound",
      search: `?startDate=${startDateRef.current.value}&endDate=${endDateRef.current.value}`,
    });
    setStartDate(startDateRef.current.value);
    setEndDate(endDateRef.current.value);
    setShouldRender(true);
  };

  const exportDataHandler = () => {
    const table_elt = tableRef.current;

    const workbook = XLSX.utils.table_to_book(table_elt, {
      dateNF: "yyyy-MM-DD hh:mm:ss",
    });

    XLSX.writeFile(workbook, "Report_Outbound.xlsx");
  };

  const getSummary = (data) => {
    smallCount = mediumCount = largeCount = 0;
    smallDays = mediumDays = mediumDays = 0;
    smallMinutes = mediumMinutes = mediumMinutes = 0;
    data.forEach((element) => {
      if (element.TotalCnt < 6) {
        smallCount++;
        smallDays += element.days;
        smallMinutes += element.minutes;
      } else if (element.TotalCnt < 16) {
        mediumCount++;
        mediumDays += element.days;
        mediumMinutes += element.minutes;
      } else {
        largeCount++;
        largeDays += element.days;
        largeMinutes += element.minutes;
      }
    });
  };

  const SearchResults = () => {
    const { data } = useSWR(
      `/api/search/outboundReport?startDate=${startDate}&endDate=${endDate}`,
      fetcher
    );
    if (data) {
      getSummary(data);
    }
    return (
      <>
        {data && (
          <Table hover ref={tableRef} responsive size="sm">
            <thead className="table-light">
              <tr>
                <th>Order Number</th>
                <th>Owner</th>
                <th>Ordered By</th>
                <th>Date Ordered</th>
                <th>Pickup Date</th>
                <th>Total Count</th>
                <th>Days</th>
                <th>Minutes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.OrderNum}>
                  <td>
                    <Link to={`/order/${item.OrderNum}?type=3`}>
                      {item.OrderNum}
                    </Link>
                  </td>
                  <td>{item.Owner} </td>
                  <td>{item.OrderedBy} </td>
                  <td>{item.DateOrdered} </td>
                  <td>{item.DatePickUp} </td>
                  <td>{item.TotalCnt} </td>
                  <td>{item.days}</td>
                  <td>{item.minutes}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} />
                <td>Small Orders</td>
                <td> {smallCount} </td>
                <td> {smallDays} </td>
                <td> {smallMinutes} </td>
              </tr>
              <tr>
                <td colSpan={4} />
                <td>Medium Orders</td>
                <td> {mediumCount} </td>
                <td> {mediumDays} </td>
                <td> {mediumMinutes} </td>
              </tr>
              <tr>
                <td colSpan={4} />
                <td>Large Orders</td>
                <td> {largeCount} </td>
                <td> {largeDays} </td>
                <td> {largeMinutes} </td>
              </tr>
            </tfoot>
          </Table>
        )}
      </>
    );
  };

  return (
    <Container fluid>
      <h4 style={{ backgroundColor: "#F0F0F0" }}>Outbound Report</h4>
      <Form onSubmit={searchHandler}>
        <Row className="mb-3">
          <Form.Group as={Col} xl={2} lg={3} md={4}>
            <Form.Label>From</Form.Label>
            <FormControl required type="date" ref={startDateRef} />
          </Form.Group>
          <Form.Group as={Col} xl={2} lg={3} md={4}>
            <Form.Label>To</Form.Label>
            <FormControl type="date" ref={endDateRef} />
          </Form.Group>
          <Col className="align-self-end">
            <Button className="me-3" type="submit">
              Details
            </Button>
            <Button variant="outline-primary" onClick={exportDataHandler}>
              Save Data (.xlsx)
            </Button>
          </Col>
        </Row>
      </Form>
      {shouldRender && <SearchResults />}
    </Container>
  );
};

export default OutboundReport;
