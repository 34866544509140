import {React,useRef} from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";

const Notes = (props) => {
  const notesRef = useRef();

  const saveHandler = () => {
    props.onSave(notesRef.current.value);
  };

  return (
    <Modal show={props.showModal} onHide={props.onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Form.Control
              as="textarea"
              rows={3}
              style={{ resize: "none" }}
              ref={notesRef}
              defaultValue={props.notes}
              autoFocus
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save Changes </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Notes;
