import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";

import authHeader from "../../auth-header";

import AddCustomer from "./addCustomer";
import EditCustomer from "./editCustomer";
import DeleteConfirmation from "../../components/delete";

const fetcher = (url) =>
  axios
    .get(url, {
      headers: authHeader(),
    })
    .then((res) => res.data);

const Customers = () => {
  // const [customers, setCustomers] = useState([]);
  const [editItem, setEditItem] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const { mutate } = useSWRConfig();
  const { data, error } = useSWR("/api/customers/list", fetcher);

  const getCustomers = () => {
    mutate("/api/customers/list");
  };

  const addCustomerHandler = (data) => {
    // console.log('addCustomerHandler',data)
    axios
      .post(
        "/api/customers/add",
        {
          customer: data,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
        getCustomers();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteCustomerHandler = () => {
    // console.log('deleteCustomerHandler',editItem)
    setShowDelete(false);
    axios
      .post(
        "/api/customers/del",
        {
          id: editItem.id,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
        getCustomers();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editCustomerHandler = (data) => {
    console.log("editCustomerHandler", data);
    axios
      .post(
        "/api/customers/update",
        {
          customer: data,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
        getCustomers();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addCustomer = (e) => {
    e.preventDefault();
    setShowAdd(true);
  };

  const editCustomer = (item) => {
    // console.log('editCustomer',item)
    setEditItem(item);
    setShowEdit(true);
  };

  const deleteCustomer = (item) => {
    setEditItem(item);
    setShowDelete(true);
  };

  return (
    <Container fluid>
      <Form onSubmit={addCustomer}>
        <Button variant="primary" type="submit" className="me-5">
          Add Customer
        </Button>
      </Form>
      {data && (
        <Table responsive hover size="sm">
          <thead className="table-light">
            <tr>
              <th>Customers</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id}>
                <td
                  onClick={() => editCustomer(item)}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "blue",
                  }}
                >
                  {item.customer}
                </td>
                <td>
                  <OverlayTrigger overlay={<Tooltip>Delete Customer</Tooltip>}>
                    <i
                      className="bi-trash"
                      style={{
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => deleteCustomer(item)}
                    />
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <DeleteConfirmation
        showModal={showDelete}
        onDelete={deleteCustomerHandler}
        onHide={() => setShowDelete(false)}
      />
      <EditCustomer
        item={editItem}
        showModal={showEdit}
        onEdit={editCustomerHandler}
        onHide={() => setShowEdit(false)}
      />
      <AddCustomer
        showModal={showAdd}
        onAdd={addCustomerHandler}
        onHide={() => setShowAdd(false)}
      />
    </Container>
  );
};

export default Customers;
