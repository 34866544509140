import React, { useState } from "react";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import EditUser from "./editUser";
import AddUser from "./addUser";

import authHeader from "../../auth-header";

const fetcher = (url) =>
  axios
    .get(url, {
      headers: authHeader(),
    })
    .then((res) => res.data);

const Users = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editItem, setEditItem] = useState([]);
  const [active, setActive] = useState(true);
  const { mutate } = useSWRConfig();
  const { data, error } = useSWR(
    `/api/users/list?active=${active ? 1 : 0}`,
    fetcher
  );

  const getUsers = () => {
    mutate(`/api/users/list?active=${active ? 1 : 0}`);
  };

  const editUserHandler = (data) => {
    // console.log('editUser',data)
    axios
      .post(
        "/api/users/update",
        {
          user: data,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        getUsers();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addUserHandler = (data) => {
    // console.log('addUser',data)
    axios
      .post(
        "/api/users/add",
        {
          user: data,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        getUsers();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addUser = (e) => {
    e.preventDefault();
    setShowAdd(true);
  };

  const editUser = (item) => {
    setEditItem(item);
    setShowEdit(true);
  };

  const handleActiveChange = (value) => {
    // console.log("active", value);
    //mutate(`/api/users/list?active=${active ? 1 : 0}`);

    setActive(value);
  };

  return (
    <Container fluid>
      <Form onSubmit={addUser}>
        <Row>
          <Col>
            <Button variant="primary" type="submit" className="me-5">
              Add User
            </Button>
          </Col>
          <Col>
            <Form.Check
              type="checkbox"
              label="Active"
              defaultChecked
              onChange={(e) => handleActiveChange(e.target.checked)}
            />
          </Col>
        </Row>
      </Form>

      {data && (
        <Table responsive hover size="sm">
          <thead className="table-light">
            <tr>
              <th>Username</th>
              <th>Real Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Customer</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={item.employeeId}
                // style={item.Edited ? { background: "lightyellow" } : {}}
              >
                <td
                  onClick={() => editUser(item)}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "blue",
                  }}
                >
                  {item.username}
                </td>
                <td>{item.realname}</td>
                <td>{item.emailaddr}</td>
                <td>{item.strRole}</td>
                <td>{item.strCustomers}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <EditUser
        item={editItem}
        showModal={showEdit}
        onEdit={editUserHandler}
        onHide={() => setShowEdit(false)}
      />
      <AddUser
        showModal={showAdd}
        onAdd={addUserHandler}
        onHide={() => setShowAdd(false)}
      />
    </Container>
  );
};

export default Users;
