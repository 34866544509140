import React, { useRef, useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'

import authHeader from '../../auth-header'

const EditOwner = props => {
  const activeRef = useRef();
  const customerRef = useRef()
  const [customers, setCustomers] = useState()

  useEffect(() => {
    // console.log("editOwnwer useEffect");
    axios
      .get('/api/customers/list', {
        headers: authHeader()
      })
      .then(function (response) {
        setCustomers(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const saveHandler = () => {
    // console.log('saveHandler',activeRef.current)
    const data = {
      customer: customerRef.current.value,
      id: props.item.id,
      active: activeRef.current.checked
    }
    props.onEdit(data)
    props.onHide()
  }

  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Edit {props.item.Owner}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className='mb-2'>
          <Form.Check
            type='checkbox'
            label='Active'
            ref={activeRef}
            defaultChecked={props.item.active}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label>Select Customer</Form.Label>
          <Form.Select ref={customerRef} defaultValue={props.item.customer}>
            {customers?.map(item => (
              <option key={item.id} s>
                {item.customer}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='default' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditOwner
