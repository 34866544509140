import { Button, Modal } from "react-bootstrap";

const DeleteConfirmation = (props) => {
  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props?.msg ? props.msg : 'Are you sure you want to delete this item?'}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={props.onDelete}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
