import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Table, Container } from "react-bootstrap";
import axios from "axios";
import useSWR from "swr";

import authHeader from "../../auth-header";

const fetcher = (url) =>
  axios.get(url, { headers: authHeader() }).then((res) => res.data);

const PartSearch = () => {
  //   const [results, setResults] = useState([]);
  const [partNum, setPartNum] = useState(0);
  const [owner,setOwner] = useState();
  const [customer,setCustomer] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    console.log("useEffect partSearch",searchParams);
    setPartNum(searchParams.get("num") || 0);
    setOwner(searchParams.get("owner") || 0);
    setCustomer(searchParams.get("customer") || 0);
    setShouldRender(true);
  }, []);

  const SearchResults = () => {
    const { data, error } = useSWR(
      `/api/search/part?num=${encodeURIComponent(partNum)}&owner=${owner}&customer=${customer}`,
      fetcher
    );
    //   if (error) return "An error has occurred.";
    return (
      <>
        {data && (
          <Table responsive size="sm">
            <thead className="table-light">
              <tr>
                <th>Order Number</th>
                <th>Date Completed</th>
                <th>Site Name</th>
                <th>Customer PO #</th>
                <th>Warehouse</th>
                <th>Total Quantity</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.OrderNum}>
                  <td>
                    <Link to={`/order/${item.OrderNum}`}>{item.OrderNum} </Link>
                  </td>
                  <td>{item.DatePickUp} </td>
                  <td>{item.SiteName} </td>
                  <td>{item.PONum} </td>
                  <td>{item.Warehouse} </td>
                  <td>{item.TotalQty} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </>
    );
  };

  return (
    <Container fluid>
      <h4 className="mb-3" style={{ backgroundColor: "#F0F0F0" }}>
        Part Search {partNum}
      </h4>
      {shouldRender && <SearchResults />}
    </Container>
  );
};

export default PartSearch;
