import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";

import authHeader from "../auth-header";

const fetcher = (url) =>
  axios
    .get(url, {
      headers: authHeader(),
    })
    .then((res) => res.data);

const OwnerSelect = (props) => {
  // const [owners, setOwners] = useState([]);
  // console.log("owner select");
  const { data, error } = useSWR(
    `/api/owners/list?customer=${props.customer}`,
    fetcher
  );

  // useEffect(() => {
  //   axios
  //     .get("/api/owners/list", { headers: authHeader() })
  //     .then(function (response) {
  //       setOwners(response.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  // choose the first entry as default
  useEffect(() => {
    if (data?.length > 0) {
      // console.log("useEffect OwnerSelect", data[0].Owner);
      if (props.selectAll) props.setOwnerHandler("All");
      else props.setOwnerHandler(data[0].Owner);
    }
  }, [data]);

  return (
    <Form.Select
      required
      defaultValue={props.owner}
      onChange={(e) => props.setOwnerHandler(e.target.value)}
      disabled={props.disabled}
    >
      {props.selectAll && (
        <option key="0" value="All">
          All
        </option>
      )}
      {data &&
        data.map((item) => (
          <option key={item.Owner} value={item.Owner}>
            {item.Owner}
          </option>
        ))}
    </Form.Select>
  );
};

export default OwnerSelect;
