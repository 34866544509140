import React, { useState, useRef, useMemo } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

import axios from 'axios'
import useSWR from 'swr'
import * as XLSX from 'xlsx'
import { useTable, useSortBy, usePagination } from 'react-table'

import authHeader from '../../auth-header'

const fetcher = url =>
  axios.get(url, { headers: authHeader() }).then(res => res.data)

const MasterSearch = () => {
  const tableRef = useRef()

  const exportDataHandler = () => {
    console.log('exportDataHandler')
    // const table_elt = tableRef.current

    // const workbook = XLSX.utils.table_to_book(table_elt, {
    //   dateNF: 'yyyy-MM-DD hh:mm:ss'
    // })

    // XLSX.writeFile(workbook, 'Master_Search.xlsx')

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Results')
    XLSX.writeFile(workbook, 'Master_Search.xlsx')
  }

  const columns = useMemo(
    () => [
      {
        Header: 'SKU',
        accessor: 'SKUNum'
      },
      {
        Header: 'Customer',
        accessor: 'Customer'
      },
      {
        Header: 'Owner',
        accessor: 'Owner'
      },
      {
        Header: 'Part #',
        accessor: 'PartNum',
        disableSortBy: true
      },
      {
        Header: 'Serial #',
        accessor: 'SerialNum',
        disableSortBy: true
      },
      {
        Header: 'ALU #',
        accessor: 'ALUPONum',
        disableSortBy: true
      },
      {
        Header: 'PO #',
        accessor: 'PONum',
        disableSortBy: true
      },
      {
        Header: 'SO #',
        accessor: 'SONum',
        disableSortBy: true
      },
      {
        Header: 'Description',
        accessor: 'Description',
        disableSortBy: true
      },
      {
        Header: 'Stock',
        accessor: 'Quantity',
        disableSortBy: true
      }
    ],
    []
  )

  const InventoryResult = ({ columns, data }) => {
    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 250 }
      },
      useSortBy,
      usePagination
    )

    /* 
        Render the UI for your table
        - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
      */
    return (
      <>
        <Table hover responsive size='sm' {...getTableProps()}>
          <thead className='table-light'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'bi-sort-down'
                          : 'bi-sort-up-alt'
                        : ''
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* <thead className='table-light'>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps()}>
                    {// Render the header
                    column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              // console.log(row.original.OrderNum)
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
        <div className='pagination'>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>
        </div>
      </>
    )

    return (
      <Container fluid>
        <Table ref={tableRef} hover responsive size='sm'>
          <thead className='table-light'>
            <tr>
              <th>SKU</th>
              <th>Customer</th>
              <th>Owner</th>
              <th>Part #</th>
              <th>Serial #</th>
              <th>ALU #</th>
              <th>PO #</th>
              <th>SO #</th>
              <th>Description</th>
              <th>Stock</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index + 1}
                style={item.Edited ? { background: 'lightyellow' } : {}}
              >
                <td
                  // onClick={() => editPart(item)}
                  style={{
                    cursor: 'pointer',
                    textDecorationLine: 'underline',
                    color: 'blue'
                  }}
                >
                  {item.SKUNum}
                </td>
                <td>{item.Customer}</td>
                <td>{item.Owner}</td>
                <td>{item.PartNum}</td>
                <td>{item.SerialNum} </td>
                <td>{item.ALUPONum} </td>
                <td>{item.PONum} </td>
                <td>{item.SONum} </td>
                <td>{item.Description} </td>
                <td>{item.Quantity} </td>
              </tr>
            ))}
          </tbody>
          {/* <tfoot>
          <tr>
            <td colSpan='9' />
            <th>Total</th>
            <th>{totalQuantity()}</th>
          </tr>
        </tfoot> */}
        </Table>
      </Container>
    )
  }

  const { data, error } = useSWR(`/api/inventory/masterSearch`, fetcher)
  if (error) return 'An error has occurred.'

  return (
    <Container fluid>
      <h4 style={{ backgroundColor: '#F0F0F0' }}>Master Search</h4>
      <Col className='align-self-end'>
        {data && data.length > 0 ? (
          <Button variant='outline-primary' onClick={exportDataHandler}>
            Save Data (.xlsx)
          </Button>
        ) : (
          <Spinner animation='border' />
        )}
      </Col>

      {data && data.length > 0 && (
        <InventoryResult columns={columns} data={data} />
      )}
      {/* {data && data.length > 0 && <div>Got Result</div>} */}
    </Container>
  )
}

export default MasterSearch
