import React, { useRef } from "react";

import { Form, Row, Col, Button, Modal } from "react-bootstrap";

const EditBatchItem = (props) => {
  const partNumRef = useRef();
  const serialNumRef = useRef();
  const aluPONumRef = useRef();
  const poNumRef = useRef();
  const soNumRef = useRef();
  const descriptionRef = useRef();
  const quantityRef = useRef();

  const saveHandler = () => {
    const savedData = {
      partNum: partNumRef.current.value,
      serialNum: serialNumRef.current.value,
      aluPONum: aluPONumRef.current.value,
      poNum: poNumRef.current.value,
      soNum: soNumRef.current.value,
      description: descriptionRef.current.value,
      quantity: quantityRef.current.value,
    };
    props.onUpdate(savedData);
    props.onHide();
  };

  return (
    <Modal show={props.showModal} onHide={props.onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Part</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Form.Label>Part #</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.partNum}
              ref={partNumRef}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Serial #</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.serialNum}
              ref={serialNumRef}
            />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Form.Label>{props.customer === "34" ? "ALU #":"Vendor"}</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.aluPONum}
              ref={aluPONumRef}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>PO #</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.poNum}
              ref={poNumRef}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>SO #</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.soNum}
              ref={soNumRef}
            />
          </Form.Group>
        </Row>
        <Form.Group className="mb-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            // as="textarea"
            // rows={2}
            defaultValue={props.description}
            placeholder="Description"
            ref={descriptionRef}
          />
        </Form.Group>
        <Row>
          <Form.Group as={Col} sm={2}>
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              defaultValue={props.quantity}
              placeholder="0"
              ref={quantityRef}
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save Changes </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditBatchItem;
