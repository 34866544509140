import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";

import authHeader from "../../auth-header";

const AddUser = (props) => {
  const userRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const roleRef = useRef();
  const customerRef = useRef();
  const [customers, setCustomers] = useState();
  const [role, setRole] = useState(0);

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    axios
      .get("/api/customers/list", {
        headers: authHeader(),
      })
      .then(function (response) {
        // console.log(response.data);
        setCustomers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleShow = () => {
    // getCustomers();
    // clear customers checked
    setCustomers((current) =>
      current.map((obj) => {
        return { ...obj, checked: 0 };
      })
    );

    setRole(0);
  };

  const handleHide = () => {
    props.onHide();
  };

  const addHandler = (e) => {
    e.preventDefault();
    // get customers
    let customerList = "";
    if (role === 0) {
      // customer.  convert customer name to id
      const found = customers.find(
        (cust) => cust.customer === customerRef.current.value
      );
      // console.log(found)
      customerList = found.id;
    } else if (role === 1) {
      // user.  make commalist of checked
      customers.forEach((element) => {
        if (element.checked) {
          if (customerList != "") customerList += ",";
          customerList += element.id;
        }
      });
      //   console.log(checked);
      //
    }
    const data = {
      username: userRef.current.value,
      password: passwordRef.current.value,
      realname: nameRef.current.value,
      emailaddr: emailRef.current.value,
      role: roleRef.current.value,
      customers: customerList,
    };
    props.onAdd(data);
    handleHide();
  };

  const handleRoleChange = (value) => {
    // console.log("handleRoleChange", value);
    setRole(parseInt(value));
  };

  const handleCustomerClick = (checked, id) => {
    // console.log("handleCustomerClick", checked, id);
    setCustomers((current) =>
      current.map((obj) => {
        if (obj.id === id) {
          return { ...obj, checked: checked ? 1 : 0 };
        }
        return obj;
      })
    );
  };

  return (
    <Modal
      show={props.showModal}
      onShow={handleShow}
      onHide={handleHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      //   centered
      animation={false}
    >
      <Form onSubmit={addHandler}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label>Username</Form.Label>
            <Form.Control
              style={{ resize: "none" }}
              placeholder="Enter Username"
              ref={userRef}
              autoFocus
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Password</Form.Label>
            <Form.Control
              style={{ resize: "none" }}
              placeholder="Set a password"
              ref={passwordRef}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Real Name</Form.Label>
            <Form.Control
              style={{ resize: "none" }}
              placeholder="Enter Real Name"
              ref={nameRef}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              style={{ resize: "none" }}
              placeholder="Enter Email Address"
              ref={emailRef}
              type="email"
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Role</Form.Label>
            <Form.Select
              ref={roleRef}
              onChange={(e) => handleRoleChange(e.target.value)}
            >
              <option value={0}>Customer</option>
              <option value={1}>User</option>
              <option value={2}>Admin</option>
            </Form.Select>
          </Form.Group>
          {role === 0 && customers && (
            <Form.Group className="mb-2">
              <Form.Label>Customer</Form.Label>
              <Form.Select ref={customerRef}>
                {customers.map((item) => (
                  <option key={item.id}>{item.customer}</option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {role === 1 && customers && (
            <Form.Group>
              <Form.Label>Customers</Form.Label>
              <ListGroup.Item>
                {customers.map((item) => (
                  <Form.Check
                    key={item.id}
                    type="checkbox"
                    label={item.customer}
                    onChange={(e) =>
                      handleCustomerClick(e.target.checked, item.id)
                    }
                  />
                ))}
              </ListGroup.Item>
            </Form.Group>
          )}
          {/* {customers && showCustomers && (
            <Form.Group className="mb-2">
              <Form.Label>Customer</Form.Label>
              <Form.Select ref={customerRef}>
                {customers.map((item) => (
                  <option key={item.id} s>
                    {item.customer}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )} */}
          {/* <Form.Group>
            <Form.Label>Customers</Form.Label>
            <ListGroup>
              <ListGroup.Item>
                <Form.Check type="checkbox" label="Bell" />
                <Form.Check type="checkbox" label="Rogers" />
                <Form.Check type="checkbox" label="Nokia" />
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Check type="checkbox" label="Bell" />
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Check type="checkbox" label="Rogers" />
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Check type="checkbox" label="Nokia" />
              </ListGroup.Item>
            </ListGroup>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleHide}>
            Cancel
          </Button>
          <Button type="submit">Add</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddUser;
