import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import axios from 'axios'
import useSWR, { useSWRConfig } from 'swr'

import authHeader from '../../auth-header'

import AddOwner from './addOwner'
import EditOwner from './editOwner'

const fetcher = url =>
  axios
    .get(url, {
      headers: authHeader()
    })
    .then(res => res.data)

const Owners = () => {
  // const [owners, setOwners] = useState();
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [editItem, setEditItem] = useState([])
  const [active, setActive] = useState(true)
  const { mutate } = useSWRConfig()
  const { data, error } = useSWR(
    `/api/owners/list?active=${active ? 1 : 0}`,
    fetcher
  )

  const getOwners = () => {
    mutate(`/api/owners/list?active=${active ? 1 : 0}`)
  }

  // useEffect(() => {
  //   getOwners();
  // }, []);

  const addOwnerHandler = data => {
    // console.log("addOwnerHandler", data);
    axios
      .post(
        '/api/owners/add',
        {
          owner: data
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        getOwners()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const addOwner = e => {
    e.preventDefault()
    setShowAdd(true)
  }

  const editOwnerHandler = data => {
    // console.log("editOwnerHandler", data);
    axios
      .post(
        '/api/owners/update',
        {
          owner: data
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        getOwners()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const editOwner = item => {
    setEditItem(item)
    setShowEdit(true)
  }

  const handleActiveChange = value => {
    //mutate(`/api/users/list?active=${active ? 1 : 0}`);

    setActive(value)
  }

  return (
    <Container fluid>
      <Form onSubmit={addOwner}>
        <Row>
          <Col>
            <Button variant='primary' type='submit' className='me-5'>
              Add Owner
            </Button>
          </Col>
          <Col>
            <Form.Check
              type='checkbox'
              label='Active'
              defaultChecked
              onChange={e => handleActiveChange(e.target.checked)}
            />
          </Col>
        </Row>
      </Form>
      {data && (
        <Table responsive hover size='sm'>
          <thead className='table-light'>
            <tr>
              <th>Owner</th>
              <th>Customer</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id}>
                <td
                  onClick={() => editOwner(item)}
                  style={{
                    cursor: 'pointer',
                    textDecorationLine: 'underline',
                    color: 'blue'
                  }}
                >
                  {item.Owner}
                </td>
                <td>{item.customer} </td>
                <td>{item.active===1?('Yes'):('No')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <EditOwner
        item={editItem}
        showModal={showEdit}
        onEdit={editOwnerHandler}
        onHide={() => setShowEdit(false)}
      />
      <AddOwner
        showModal={showAdd}
        onAdd={addOwnerHandler}
        onHide={() => setShowAdd(false)}
      />
    </Container>
  )
}

export default Owners
