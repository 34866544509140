import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'
import useToken from './useToken'

import LoginForm from './components/Login/login'
import Header from './components/Header/header'
import NavBar from './components/NavBar/navbar'
import OrdersSummary from './pages/Orders/ordersSummary'
import ShowOrder from './pages/Orders/showOrder'
import InventoryInput from './pages/inventory/input'
import InventoryBatch from './pages/inventory/batch'
import CreateOrder from './pages/Orders/create'
import PickOrder from './pages/Orders/pickOrder'
import EditOrder from './pages/Orders/editOrder'
import Settings from './components/Login/settings'
import InventorySearch from './pages/search/inventorySearch'
import InboundSearch from './pages/search/inboundSearch'
import OutboundSearch from './pages/search/outboundSearch'
import OutboundReport from './pages/reports/outboundReport'
import PartSearch from './pages/search/partSearch'
import Owners from './pages/Admin/owners'
import Users from './pages/Admin/users'
import AddUser from './pages/Admin/addUser'
import Customers from './pages/Admin/customers'
import MasterSearch from './pages/reports/masterSearch'

const App = () => {
  const { token, setToken } = useToken()
  const [customer, setCustomer] = useState()
  const [role, setRole] = useState()

  const handleLogout = () => {
    //console.log("handleLogout");
    setToken('')
    setCustomer(null)
  }

  //console.log("app.  token="+token)

  if (!token) {
    return <LoginForm setToken={setToken} />
  }

  //console.log("In app");
  const user = JSON.parse(localStorage.getItem('user'))

  const handleCustomerChange = value => {
    console.log("set customer", value);
    setCustomer(value)
  }

  const handleUpdateRole = value => {
    // console.log("setRole", value);
    setRole(value)
  }

  return (
    <BrowserRouter>
      <Header
        doLogout={handleLogout}
        realName={user.realname}
        updateCustomer={handleCustomerChange}
      />
      <NavBar updateRole={handleUpdateRole} />
      {customer >= 0 && (
        <Routes>
          <Route path='/' element={<OrdersSummary customer={customer} />} />
          <Route
            path='/inventory/search'
            element={<InventorySearch customer={customer} role={role} />}
          />
          <Route
            path='/inventory/input'
            element={<InventoryInput customer={customer} />}
          />
          <Route
            path='/inventory/batch'
            element={<InventoryBatch customer={customer} />}
          />
          <Route path='/create' element={<CreateOrder customer={customer} />} />
          <Route
            path='/search/inbound'
            element={<InboundSearch customer={customer} />}
          />
          <Route
            path='/search/outbound'
            element={<OutboundSearch customer={customer} />}
          />
          <Route
            path='/search/part'
            element={<PartSearch customer={customer} />}
          />
          <Route path='/reports/masterSearch' element={<MasterSearch />} />
          <Route
            path='/order/:orderId'
            element={<ShowOrder customer={customer} />}
          />
          <Route
            path='/pickOrder/:orderId'
            element={<PickOrder customer={customer} />}
          />
          <Route
            path='/processOrder/:orderId'
            element={<ShowOrder customer={customer} />}
          />
          <Route
            path='/editOrder/:orderId'
            element={<EditOrder customer={customer} />}
          />
          <Route path='settings' element={<Settings />} />
          <Route path='/admin/owners' element={<Owners />} />
          <Route path='/admin/customers' element={<Customers />} />
          <Route path='/admin/users' element={<Users />} />
          <Route path='/admin/addUser' element={<AddUser />} />
          <Route
            path='*'
            element={
              <main style={{ padding: '1rem' }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      )}
    </BrowserRouter>
  )
}

export default App
