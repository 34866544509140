import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import useSWR from 'swr';

import authHeader from "../../auth-header";

// const fetcher = (url) => fetch(url).then((res) => res.json());
const fetcher = (url) => axios.get(url).then((res) => res.data);



const OrdersInProcess = (props) => {
  // const [orders, setOrders] = useState([]);

  // useEffect(() => {
  //   axios
  //   .get("/api/orders/inProcess", { headers: authHeader() })
  //   .then(function (response) {
  //     setOrders(response.data);
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  // }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: "OrderNum",
      },
      {
        Header: "Owner",
        accessor: "Owner",
      },
      {
        Header: "Site",
        accessor: "SiteName",
      },
      {
        Header: "Date Ordered",
        accessor: "DateOrdered",
      },
      {
        Header: "Date Available",
        accessor: "DateAvailable",
      },
      {
        Header: "In Process By",
        accessor: "InProcessBy",
      },
    ],
    []
  );


  // const Orders = () => (
  //   <>
  //     <h3 style={{ textAlign: "center" }}>Orders In Process</h3>
  //     <Table responsive size="sm">
  //       <thead>
  //         <tr>
  //           <th>Order</th>
  //           <th>Owner</th>
  //           <th>Site</th>
  //           <th>Date Ordered</th>
  //           <th>Date Available</th>
  //           <th>In Process By</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {orders.map((order) => (
  //           <tr key={order.OrderNum}>
  //             <td>
  //               <Link to={`/order/${order.OrderNum}?type=2`}>{order.OrderNum} </Link>
  //             </td>
  //             <td>{order.Owner} </td>
  //             <td>{order.SiteName} </td>
  //             <td>{order.DateOrdered} </td>
  //             <td>{order.DateAvailable} </td>
  //             <td>{order.InProcessBy} </td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </Table>
  //   </>

  // )

  function Orders({ columns, data }) {
    // Use the useTable Hook to send the columns and data to build the table
    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      rows, // rows for the table based on the data passed
      prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

    /* 
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    return (
      <>
        {/* <h3 style={{ textAlign: "center" }}>Orders In Process</h3> */}
        <h4 >Orders In Process</h4>


        <Table hover responsive size="sm" {...getTableProps()}>
          {/* <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "bi-sort-down"
                          : "bi-sort-up-alt"
                        : ""
                    }
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}
          <thead className="table-light">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              // console.log(row.original.OrderNum)
              prepareRow(row);
              return (
                <tr  {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return j === 0 ? (
                      <td key={row.original.OrderNum}>
                        <Link to={`/order/${cell.value}?type=2`}>
                          {cell.render("Cell")}{" "}
                        </Link>
                      </td>
                    ) : (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }

  const { data, error } = useSWR(`/api/orders/inProcess?customer=${props.customer}`, fetcher);
  if (error) return "An error has occurred.";
  // if (!data) return "Loading...";
  // return <>{orders.length > 0 && <Orders columns={columns} data={orders} />}</>;
  return <>{data && data.length > 0 && <Orders columns={columns} data={data} />}</>;
};

export default OrdersInProcess;
