import React, { useRef } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";

const ShippingAddress = (props) => {
  const saveHandler = (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    // console.log(e.target.elements);
    const obj = Object.fromEntries(formdata);
    // console.log(obj);
    props.onSave(obj);
  };

  return (
    <Modal show={props.showModal} onHide={props.onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={saveHandler}>
        <Modal.Body>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.data?.CompanyName}
                name="CompanyName"
                autoFocus
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Form.Label>Contact Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.data?.ContactName}
                name="ContactName"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.data?.ContactNumber}
                name="ContactNumber"
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                className="mb-2"
                type="text"
                defaultValue={props.data?.Street}
                placeholder="Address 1"
                name="Street"
              />
              <Form.Control
                type="text"
                defaultValue={props.data?.Street2}
                placeholder="Address 2"
                name="Street2"
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.data?.City}
                name="City"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Province</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.data?.Province}
                name="Province"
              />
            </Form.Group>
            <Form.Group as={Col} sm={2}>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.data?.PostalCode}
                name="PostalCode"
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={props.onHide}>
            Cancel
          </Button>
          <Button type="submit">Save Changes </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ShippingAddress;
