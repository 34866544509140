import { useState, createRef } from "react";
import { Container, Form, Col, Button, Modal } from "react-bootstrap";

import Password from "./password";
import ConfirmRequest from "../confirm";

const Settings = (props) => {
  const currentRef = createRef();
  const newRef = createRef();
  const confirmRef = createRef();
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showModal,setShowModal] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(
    //   "refs = %s %s %s",
    //   currentRef.current.value,
    //   newRef.current.value,
    //   confirmRef.current.value
    // );
    if (newRef.current.value === confirmRef.current.value) {
      setPasswordMatch(true);
      // make the call to see if ok to change password
      setShowModal(true)
    } else setPasswordMatch(false);
    // setInvalidPassword(true);
  };

  const PasswordChanged = () => {
    return (
      <Modal show={showModal} onHide={()=>setShowModal(false)} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Password Changed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-info text-center">
            Your password was successfully changed.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>setShowModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  

  return (
    <Container fluid>
      <Form onSubmit={submitHandler}>
        <h3 className="mt-3" style={{ textAlign: "center" }}>
          Change Password
        </h3>
        <Form.Group as={Col} sm={3}>
          <Form.Label>Current Password</Form.Label>
          <Password
            invalid={invalidPassword}
            invalidMessage="Password is incorrect"
            inputRef={currentRef}
          />
        </Form.Group>
        <Form.Group as={Col} sm={3}>
          <Form.Label>New Password</Form.Label>
          <Password
            invalid={!passwordMatch}
            invalidMessage="Passwords do not match!"
            inputRef={newRef}
          />
        </Form.Group>
        <Form.Group as={Col} sm={3}>
          <Form.Label>Confirm Password</Form.Label>
          <Password
            invalid={!passwordMatch}
            invalidMessage="Passwords do not match!"
            inputRef={confirmRef}
          />
        </Form.Group>
        <Button type="submit">Submit</Button>
      </Form>
      <PasswordChanged/>
    </Container>
  );
};

export default Settings;
