import React, { useState } from "react";
import { Button, InputGroup, FormControl, Form } from "react-bootstrap";

const Password = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    // <Col sm="3">
    <InputGroup className="mb-3">
      <FormControl
        ref={props.inputRef}
        placeholder="Enter Password"
        type={passwordShown ? "text" : "password"}
        required
        isInvalid={props.invalid}
      />
      <Button
        variant="secondary"
        onClick={() => setPasswordShown(!passwordShown)}
      >
        <i className={passwordShown ? "bi-eye" : "bi-eye-slash"}></i>
      </Button>
      <Form.Control.Feedback type="invalid">
        {props.invalidMessage}
      </Form.Control.Feedback>
    </InputGroup>
    // </Col>
  );
};

export default Password;
